<template>
  <div
    class="g-box g-role-add-box g-role-add-box-padding player-demo"
    v-loading="loading"
  >
    <div class="m-form-inline-mn" data-m="client">
      <div class="page-content">
        <div class="main-content clearfix">
          <el-row :gutter="40">
            <el-col :sm="24" :md="16">
              <div class="m-left">
                <div class="video-title">
                  <div class="title">{{ form.title }}</div>
                  <div class="title-createInfo">
                    <div class="user">
                      {{ form.createUserName }}
                    </div>
                    <div class="clock">
                      {{
                        form.createTime
                          ? $moment(form.createTime).format("YYYY-MM-DD")
                          : ""
                      }}
                    </div>
                  </div>
                  <div class="video-box" ref="playerTemp">
                    <div class="aliplayer">
                      <div class="aliplayer-box">
                        <div>
                          <div class="aliplayer-box-error"></div>
                          <div>
                            播放出错啦, 请尝试退出
                            <span @click="reloadVideo">重试</span> 或
                            <span @click="reloadPage">刷新</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="video-description">
                    <div class="title">{{ $t("Tzh.简介") }}:</div>
                    <p style="margin-top: 20px">
                      {{ form.introduction || "暂无简介" }}
                    </p>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="24" :md="8">
              <div class="m-right">
                <div class="play-content">
                  <div style="height: 100%; overflow-x: hidden">
                    <div
                      v-if="
                        form.recommendVideoList &&
                        form.recommendVideoList.length == 0
                      "
                    >
                      暂无视屏
                    </div>
                    <div v-else>
                      <div
                        class="item clearfix"
                        v-for="(item, index) in form.recommendVideoList"
                        :key="item.id"
                        @click="getDetils({ id: item.id })"
                      >
                        <div class="img-wrap">
                          <img :src="item.coverImgUrl | preUrl" alt="图片" />
                          <span class="video-time">{{
                            item.videoDuration
                          }}</span>
                          <div class="play-cover"></div>
                        </div>
                        <div class="con-wrap">
                          <div class="title">
                            <div class="norow">{{ item.title }}</div>
                          </div>
                          <div class="description tworow">
                            {{ item.introduction || "暂无简介" }}
                          </div>
                          <div class="createInfo">
                            <div class="user">
                              {{ form.createUserName }}
                            </div>
                            <div class="clock">
                              {{
                                form.updateTime
                                  ? $moment(form.updateTime).format(
                                      "YYYY-MM-DD"
                                    )
                                  : ""
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Loading from './components/Loading'
import getConfig from '../../../config'
import defaultImg from '@/assets/img/default-img.png'
function createObjectURL (object) {
  return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object)
}
export default {
  components: {
    Loading
  },
  data () {
    return {
      videoLoading: true,
      videoPlayer: null,
      form: {},
      videoSrc: '',
      loading: true,
      player: null,
      playingVideo: {},
      fullScreen: false
    }
  },
  mounted () {
    this.getDetils({ id: this.$route.query.id })
  },
  methods: {
    createDemoPlayer (source, cover) {
      if (document.getElementById('player-con') === null) {
        const playerDomWrap = this.$refs.playerTemp
        const playerDom = document.createElement('div')
        playerDom.setAttribute('id', 'player-con')
        playerDomWrap.appendChild(playerDom)

        const props = {
          id: 'player-con',
          width: '100%',
          height: '405px',
          autoplay: false,
          language: 'zh-cn',
          source: source,
          isLive: false,
          cover: cover,
          components: [],
          skinLayout: [
            { name: 'bigPlayButton', align: 'cc' },
            {
              name: 'H5Loading', align: 'cc'
            },
            { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
            { name: 'infoDisplay' },
            { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
            { name: 'thumbnail' },
            {
              name: 'controlBar',
              align: 'blabs',
              x: 0,
              y: 0,
              children: [
                { name: 'progress', align: 'blabs', x: 0, y: 44 },
                { name: 'playButton', align: 'tl', x: 15, y: 12 },
                { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
                { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
                { name: 'setting', align: 'tr', x: 15, y: 12 },
                { name: 'volume', align: 'tr', x: 5, y: 10 }
              ]
            }
          ]

        }
        this.player = new Aliplayer(props)
        this.player.on('init', function (e) {
          document.querySelector('.aliplayer').style.display = 'none'
        })
        this.player.on('error', function (e) {
          document.querySelector('.prism-ErrorMessage').style.display = 'none'
          document.querySelector('.aliplayer').style.display = 'block'

        })
      } else {
        this.player.loadByUrl(source)
        this.player.setCover(cover)
      }
      // this.player.on('requestFullScreen', this.fullScreenHandle);
      // this.player.on('cancelFullScreen', this.cancelFullScreenHandel);
    },
    getDetils ({ id }) {
      this.loading = true
      this.videoLoading = true
      api.tzh.findById({ id }).then(res => {
        this.loading = false
        this.form = res.data
        this.createDemoPlayer(getConfig().ftpUrl + '/' + res.data.transcodeVideoUrl, res.data.coverImgUrl ? this.preUrl(res.data.coverImgUrl) : defaultImg)
        this.videoLoading = false
      })
    },
    reloadVideo () {
      if (!this.player) {
        return
      }
      document.querySelector('.aliplayer').style.display = 'none'
      document.querySelector('.prism-ErrorMessage').style.display = 'block'
      var url = this.player.getSourceUrl()
      this.player.loadByUrl(url)
    },
    reloadPage () {
      location.href = location.href
    }
  }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa;
}
.player-demo * {
  box-sizing: border-box;
}

.video-box {
  width: 100%;
  height: 405px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #707070;
  position: relative;
}

.video-title {
  width: 100%;
  margin-top: 10px;

  & > .title {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 30px;
    color: #000000;
    opacity: 0.85;
    margin-bottom: 10px;
  }

  .title-createInfo {
    display: flex;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 12px;
    .user {
      margin-right: 5px;
      &::before {
        position: relative;
        top: 2px;
        content: "";
        margin-right: 5px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("~assets/img/icon/user-icon.png") no-repeat center/cover;
      }
    }
    .clock {
      &::before {
        position: relative;
        top: 2px;
        content: "";
        margin-right: 2px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("~assets/img/icon/clock-icon.png") no-repeat
          center/cover;
      }
    }
  }
}

.player-demo .main-content .m-right .play-content .img-wrap img {
  object-fit: cover;
}
.main-footer {
  display: block;
  height: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-demo {
  min-height: 100%;
  .main-content {
    color: #666666;
    width: 100%;
    margin: 0 auto;
    padding-left: 64px;
    padding-right: 64px;
    .m-left {
      width: 100%;
      .vd-type {
        margin-top: 25px;
        .wrap {
          float: left;
          border: 1px solid #73777a;
        }
        a {
          font-size: 18px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          width: 105px;
          height: 38px;
          display: block;
          float: left;
          line-height: 38px;
          cursor: pointer;
          &.active {
            background: #30adf2;
          }
        }
      }

      .video-description {
        margin-top: 26px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.85);
        p {
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
          opacity: 1;
        }
      }
    }
    .m-right {
      width: 100%;
      .play-list {
        margin-top: 20px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 20px;
        line-height: 24px;
      }
      .play-content {
        height: 650px;
        .item {
          margin-bottom: 8px;
          cursor: pointer;
        }
        .img-wrap {
          float: left;
          height: 134px;
          position: relative;
          width: 178px;
          padding-top: 10px;
          padding-bottom: 10px;

          img {
            display: block;
            width: 178px;
            height: 114px;
          }
          .video-time {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            background-color: rgba(0, 0, 0, 0.8);
            bottom: 15px;
            color: #fff;
            display: block;
            font-size: 12px;
            font-weight: bold;
            padding: 0 5px;
            position: absolute;
            right: 5px;
            text-align: right;
          }
          .play-cover {
            position: absolute;
            display: none;
            top: 10px;
            left: 0;
            right: 0;
            bottom: 10px;
            background: rgba(0, 0, 0, 0.3) url("~assets/img/play-btn.png")
              center center no-repeat;
            background-size: 22px 22px;
          }
          &:hover {
            .play-cover {
              display: block;
            }
          }
        }
        .con-wrap {
          width: calc(100% - 178px);
          height: 134px;
          float: right;
          padding: 10px;
          position: relative;
          .title {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            color: #333333;
            display: -webkit-box;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 18px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 92px;
            overflow: hidden;
          }
          .description {
            margin-top: 8px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 66px;
            overflow: hidden;
          }
          .author {
            color: #c3c5c6;
            font-size: 12px;
            letter-spacing: 0;
            margin-top: 8px;
            text-align: left;
          }
          .createInfo {
            display: flex;
            position: absolute;
            bottom: 0;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 12px;
            .user {
              margin-right: 5px;
              &::before {
                position: relative;
                top: 2px;
                content: "";
                margin-right: 5px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url("~assets/img/icon/user-icon.png") no-repeat
                  center/cover;
              }
            }
            .clock {
              &::before {
                position: relative;
                top: 2px;
                content: "";
                margin-right: 2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url("~assets/img/icon/clock-icon.png") no-repeat
                  center/cover;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .player-demo .main-content {
    width: 100%;
    padding: 0 20px;
  }
  .player-demo .main-content .m-left {
    width: 100%;
  }
  .player-demo .main-content .m-right {
    width: 100%;
  }
  .player-demo .main-content .m-right .play-content .con-wrap {
    width: 185px;
  }
}
</style>
